<template>
  <b-img
    src="@/assets/images/illustrations/line_claw.svg"
    class="temp-logo"
  />
<!--  <swiper-->
<!--    class="swiper-navigations"-->
<!--    :options="swiperOptions"-->
<!--  >-->
<!--    <swiper-slide-->
<!--      v-for="(data, index) in slides"-->
<!--      :key="index"-->
<!--    >-->
<!--      <b-img-->
<!--        :src="data.image"-->
<!--        fluid-->
<!--      />-->
<!--    </swiper-slide>-->
<!--    <div-->
<!--      slot="button-next"-->
<!--      class="swiper-button-next"-->
<!--    />-->
<!--    <div-->
<!--      slot="button-prev"-->
<!--      class="swiper-button-prev"-->
<!--    />-->
<!--  </swiper>-->
</template>

<script>
// import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
// import 'swiper/css/swiper.css'
import { BImg } from 'bootstrap-vue'

export default {
  name: 'WhyMindfuliSlideshow',
  components: {
    // Swiper,
    // SwiperSlide,
    BImg,
  },
  data() {
    return {
      slides: [
        {
          topText: 'Personalized to <span class="text-primary">You</span>',
          middleText: 'We\'ll ask you a series of questions to help us cater the app specifically for you.',
          bottomText: 'Learn more about <a href="#" class="text-secondary">Adaptive Experience</a>?',
          image: '@/assets/images/illustrations/line_claw.svg',
        },
      ],
      swiperOptions: {
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 30,
      },
    }
  },
}
</script>

<style scoped>
.temp-logo {
  width: 50%;
  height: 50vh;
}
</style>
