<template>
  <generic-loading v-if="!interactionEnabled" />
  <div
    v-else
    class="auth-wrapper auth-v2"
  >
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <mindfuli-logo />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left -->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <why-mindfuli-slideshow />
        </div>
      </b-col>
      <!-- Left -->

      <!-- Right -->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-button
            variant="primary"
            block
            @click="login"
          >
            sign in
          </b-button>
          <b-button
            variant="primary"
            block
            @click="signUp"
          >
            create account
          </b-button>
        </b-col>
      </b-col>
      <!-- Left -->
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BLink, BButton,
} from 'bootstrap-vue'
import MindfuliLogo from '@core/layouts/components/Logo.vue'
import WhyMindfuliSlideshow from '@/components/WhyMindfuliSlideshow.vue'
import GenericLoading from '@/components/GenericLoading.vue'

export default {
  name: 'LaunchPad',
  components: {
    GenericLoading,
    MindfuliLogo,
    WhyMindfuliSlideshow,
    BRow,
    BCol,
    BLink,
    BButton,
  },
  data() {
    return {
      interactionEnabled: false,
    }
  },
  created() {
    const params = new URLSearchParams(window.location.search)
    switch (params.get('action')) {
      case 'register':
        this.signUp()
        break
      case 'login':
        this.login()
        break
      default:
        this.$nextTick(() => {
          this.interactionEnabled = true
        })
        break
    }
  },
  methods: {
    login() {
      this.$auth.loginWithRedirect()
    },
    signUp() {
      this.$auth.loginWithRedirect({
        screen_hint: 'signup',
      })
    },
  },
}
</script>

<style lang="scss">
@import './src/@core/scss/vue/pages/page-auth.scss';
</style>
